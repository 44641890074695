import request from '@/utils/request'

//获取直播间
export const joinLive = (params) => {
    return request({
        url: '/system/liveRoom/info',
        method: 'get',
        params: params,
    })
}


export const getOnlinePeriod = (params) => {
    return request({
        url: '/system/schedule/getOnlinePeriod',
        method: 'get',
        params,
    })
}

export const setOnlinePeriod = (data) => {
    return request({
        url: '/system/schedule/setOnlinePeriod',
        method: 'post',
        data,
    })

}

export const setAppointment = (data) => {
    return request({
        url: '/system/schedule/setAppointment',
        method: 'post',
        data,
    })
}

export const deleteonlinePeriod = (data) => {
    return request({
        url: '/system/schedule/deleteOnlinePeriod',
        method: 'delete',
        data,
    })
}


export const getOnlinePeriodPage = (params) => {
    return request({
        url: '/system/schedule/getOnlinePeriodPage',
        method: 'get',
        params,
    })
}

//获取周模板 /scheduleTemplate/getWeekTemplate
export const getWeekTemplate = (params) => {
    return request({
        url: '/system/scheduleTemplate/getWeekTemplate',
        method: 'get',
        params,
    })
}
//保存周模板 
export const setWeekTemplate = (data) => {
    return request({
        url: '/system/scheduleTemplate/setWeekTemplate',
        method: 'post',
        data,
    })

}

//获取不在线时段 /scheduleTemplate/getWeekTemplate
export const getUnavailableList = (params) => {
    return request({
        url: '/system/scheduleTemplate/getUnavailableList',
        method: 'get',
        params,
    })
}
//保存周模板 
export const setUnavailableList = (data) => {
    return request({
        url: '/system/scheduleTemplate/setUnavailableList',
        method: "post",
        data,
    })

}
///system/schedule/getReserveApptPage
//未来
export const getReserveApptPage = (params) => {
    return request({
        url: '/system/schedule/getReserveApptPage',
        method: 'get',
        params,
    })
}

//历史
export const getHistoryApptPage = (params) => {
    return request({
        url: '/system/schedule/getHistoryApptPage',
        method: 'get',
        params,
    })
}


